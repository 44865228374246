import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
// import $ from 'jquery';
import Carousel, { consts } from 'react-elastic-carousel'
import Match from '../match/Match'
import Complete from '../complete/Complete'
import Choose from '../choose/Choose'
import MapClick from '../mapClick/MapClick'
import './Exercises.css'

export default function Exercises({ packageName, bookName }) {

  // should start with large array then setSlides will reduce it otherwise
  // initialActiveIndex will not work
  // const [slides, setSlides] = useState(new Array(10).fill(0))
  const [exercises, setExercises] = useState([])
  const [isRTL, setIsRTL] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)

  const myArrow = ({ type, onClick, isEdge }) => {
    const className = isRTL ?
      (type === consts.PREV ? 'arrow-right' : 'arrow-left') :
      (type === consts.PREV ? 'arrow-left' : 'arrow-right')
    const displayMode = isEdge ? 'none' : ''
    return (
      <a className={className} onClick={onClick} style={{ display: displayMode }}></a>
    )
  }

  useEffect(() => {
    fetch(`/content/${packageName}/${bookName}/exercises.json`)
      .then(resp => resp.json())
      .then(result => {
        setExercises(result)
      })
  }, [packageName, bookName])


  // useEffect(() => {
  //   const resizeListener = () => {
  //     $('.h5p-brainy-book').height(window.innerHeight)
  //     // $('.landscape-grid').height(window.innerHeight - 50)
  //     // $('.portrait-grid').height(window.innerHeight - 50)
  //   }
  //   // set resize listener
  //   window.addEventListener('resize', resizeListener)
  //   resizeListener()
  //   // if (!document.getElementById('manifest_id')) {
  //   //   var link = document.createElement('link')
  //   //   link.id = 'manifest_id'
  //   //   link.rel = 'manifest'
  //   //   link.href = `/content/${appName}/manifest.json`
  //   //   console.log(link)
  //   //   document.head.appendChild(link)
  //   // }
  //   return () => {
  //     window.removeEventListener('resize', resizeListener)
  //   }
  // })

  const onItemChange = (currentItemObject, currentPageIndex) => {
    // console.log(currentItemObject, currentPageIndex)
    setCurrentPage(currentPageIndex + 1)
  }

  let backPath = '/interface/default_bg.jpg'

  const getExercise = (ex, index) => {
    switch (ex.type) {
      case 'match':
        return <Match key={index}
          packageName={packageName}
          bookName={bookName}
          exId={ex.id}
          isRTL={isRTL} />
      case 'fillBlanks':
        return <Complete key={index}
          packageName={packageName}
          bookName={bookName}
          exId={ex.id}
          isRTL={isRTL} />
      case 'map-click-answer':
        return <MapClick key={index}
          packageName={packageName}
          bookName={bookName}
          pageId={index}
          exId={ex.id}
          isRTL={isRTL} />
      case 'choose':
        return <Choose key={index}
          packageName={packageName}
          bookName={bookName}
          exId={ex.id}
          isRTL={isRTL} />
      default:
        return <div key={index}>{ex.id} : {ex.type}</div>
    }
  }

  return (
    <div className='h5p-brainy-book' style={{
      backgroundImage: `url(${backPath})`,
      backgroundSize: 'cover',
    }}>
      {/* <MapClick packageName={packageName}
                bookName={bookName}
                pageId={0}
                exId='ex5'
                isRTL={isRTL} /> */}
      <Carousel
        isRTL={isRTL}
        enableSwipe={false}
        renderArrow={myArrow}
        pagination={false}
        onChange={onItemChange} >
        {exercises.map((ex, index) => getExercise(ex, index))}
      </Carousel>
      <div className='pageFooter'>{currentPage} / {exercises.length}</div>
    </div>
  )
}

Exercises.propTypes = {
  packageName: PropTypes.string.isRequired,
  bookName: PropTypes.string.isRequired
};