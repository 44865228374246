const rightAnswer = [
  'right1.mp3',
];

const wrongAnswer = [
  'wrong1.mp3',
];

const allRight = [
  'allright1.mp3',
  'allright2.mp3',
  'allright3.mp3',
  // 'allright4.mp3',
];

const allRightEn = [
  'allrightEn1.mp3',
  'allrightEn2.mp3',
  'allrightEn3.mp3',
];

const allPagesRight = [
  'allpagesright1.mp3',
  'allpagesright2.mp3',
];

const allPagesRightEn = [
  'allpagesrightEn1.mp3',
  'allpagesrightEn2.mp3',
];

const SND_URL = '/interface/snd/';
export const RIGHT_IMG = '/interface/right.png';
export const WRONG_IMG = '/interface/wrong.png';

class Hints {
  constructor() {
    this.audio = new Audio();
    this.audioFiles = [];
    this.current = -1;

    var self = this;
    this.audio.onended = () => { self.playNext() };
  }

  getPath(path, appName) {
    return '/content/' + appName + '/' + path;
  }

  playHint(src) {
    this.audio.pause();
    this.audio.src = SND_URL + src;
    let promise = this.audio.play();
    if (promise !== undefined) {
      promise.catch(() => {
      });
    }
  }

  play(snd, id) {
    this.id = id;
    this.audioFiles = snd;
    this.current = -1;
    this.playNext();
  }

  playNext() {
    this.current++;
    if (this.current >= this.audioFiles.length) {
      this.current = -1;
      this.audioFiles = [];
    } else if (this.current >= 0 && this.current < this.audioFiles.length) {
      const snd = this.audioFiles[this.current];
      if (snd !== undefined) {
        let path = '/content/' + this.id + '/' + snd.path;
        this.audio.src = path;
        let promise = this.audio.play();
        if (promise !== undefined) {
          promise.catch((e) => {
            console.log('Play Audio Error:', e);
          });
        }
      }
    }
  }

  playFromArray(sndArray) {
    this.playHint(sndArray[Math.floor(Math.random() * sndArray.length)]);
  }

  playRight() { this.playFromArray(rightAnswer); }
  playWrong() { this.playFromArray(wrongAnswer); }

  playAllRight(isRTL = true) {
    if (isRTL) { this.playFromArray(allRight); }
    else { this.playFromArray(allRightEn); }
  }

  playAllPagesRight(isRTL = true) {
    if (isRTL) { this.playFromArray(allPagesRight); }
    else { this.playFromArray(allPagesRightEn); }
  }

  /***********************************
   * Related to Images
   ***********************************/
  isTxt(txt = '') {
    return !(txt.toLowerCase().indexOf('.png') > 0);
  }

  isImg(img, txt = '') {
    return img != null || txt.toLowerCase().indexOf('.png') > 0;
  }

  getImg(img, id, txt = '') {
    if (img != null) {
      return '/content/' + id + '/' + img.path;
    }
    if (txt.toLowerCase().indexOf('.png') > 0) {
      return '/interface/' + txt.toLowerCase();
    }
    return '';
  }

  /***********************************
   * Related to shuffling and paging
   ***********************************/
  shuffleArray(array) {
    const oldArray = [];
    for (const item of array) {
      oldArray.push(item);
    }
    const newArray = [];
    while (oldArray.length) {
      const i = Math.floor(Math.random() * oldArray.length);
      newArray.push(oldArray.splice(i, 1)[0]);
    }
    return newArray;
  }

  paging(itemPerPage, array) {
    let tmp = [];
    let result = [];
    if (array) {
      for (var i = 0; i < array.length; i++) {
        if (i % itemPerPage === 0 && i !== 0) {
          result.push(tmp);
          tmp = [];
        }
        tmp.push(array[i]);
      }
      if (tmp.length > 0) {
        result.push(tmp);
      }
    }
    return result;
  }
}

export const hints = new Hints();

