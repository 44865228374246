import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import './VideoPlayer.css'

export default function VideoPlayer({ track, autoNext, onNext }) {

  const [playing, setPlaying] = useState(true)
  const player = useRef()

  const handlePlay = () => {
    setPlaying(true)
  }

  const handlePause = () => {
    setPlaying(false)
  }

  const handleEnded = () => {
    console.log('onEnded')
    if (autoNext && onNext()) {
      setPlaying(true)
    } else {
      setPlaying(false)
    }
  }

  return (
    <ReactPlayer
      ref={player}
      className='videoPlayer'
      width='100%'
      height='100%'
      url={track.url}
      playing={playing}
      controls={true}
      onPlay={handlePlay}
      onPause={handlePause}
      onEnded={handleEnded}
    />
  );
}

VideoPlayer.propTypes = {
  track: PropTypes.object.isRequired,
  autoNext: PropTypes.bool.isRequired,
  onNext: PropTypes.func.isRequired,
};

