import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import Duration from './Duration'
import './AudioPlayer.css'

export default function AudioPlayer({ track, skin, autoNext, onPrevious, onNext }) {

  const [volume, setVolume] = useState(0.8)
  const [played, setPlayed] = useState(0)
  const [loaded, setLoaded] = useState(0)
  const [seeking, setSeeking] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [duration, setDuration] = useState(0)

  const player = useRef()

  const handlePlay = () => {
    setPlaying(true)
  }

  const handlePause = () => {
    setPlaying(false)
  }

  const handleEnded = () => {
    console.log('onEnded')
    if (autoNext && onNext()) {
      setPlaying(true)
    } else {
      setPlaying(false)
    }
  }

  const handleProgress = progress => {
    // We only want to update time slider if we are not currently seeking
    if (!seeking) {
      setPlayed(progress.played)
      setLoaded(progress.loaded)
    }
  }

  const handleDuration = (duration) => {
    setDuration(duration)
  }

  const handleSeekMouseDown = e => {
    console.log('handleSeekMouseDown', e)
    setSeeking(true)
  }

  const handleSeekChange = e => {
    console.log('handleSeekChange', e)
    setPlayed(parseFloat(e.target.value))
  }

  const handleSeekMouseUp = e => {
    console.log('handleSeekMouseUp', e)
    setSeeking(false)
    player.current.seekTo(parseFloat(e.target.value))
  }

  const handleVolumeChange = e => {
    setVolume(parseFloat(e.target.value))
  }

  const handlePlayPause = () => {
    setPlaying(!playing)
  }

  const handlePrevious = () => {
    onPrevious()
  }

  const handleNext = () => {
    onNext()
  }

  // style={{
  //   backgroundImage: `url(${skin}bg_sw720_xhdpi.png)`,
  //   backgroundSize: 'contain'
  // }}
  const styles = {
    audioContainer: {
      "--backgroundImage": `url(${skin}bg_sw720_hdpi.png)`,
      // "--backgroundImageMobile": `url(${skin}bg_sw720_hdpi.png)`
    }
  }
  return (
    <div className='audioContainer' style={styles.audioContainer}>
      <ReactPlayer
        ref={player}
        className='react-player'
        width='100%'
        height='0px'
        url={track.url}
        playing={playing}
        volume={volume}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={handleEnded}
        onProgress={handleProgress}
        onDuration={handleDuration}
      />
      <div className='audioControls'>
        <h2 className='audioTitle'>{track.title}</h2>
        <div className='audioControlLine'>
          <img className='controlButton' src={`${skin}btn_rewind.png`} alt='Previous' onClick={handlePrevious} />
          {playing ?
            <img className='controlButton' src={`${skin}btn_pause.png`} alt='Pause' onClick={handlePlayPause} /> :
            <img className='controlButton' src={`${skin}btn_play.png`} alt='Play' onClick={handlePlayPause} />
          }
          <img className='controlButton' src={`${skin}btn_forward.png`} alt='Next' onClick={handleNext} />
        </div>
        <div className='audioControlLine'>
          <img className='loudSpeaker' src={`${skin}speaker.png`}/>
          <input type='range' min={0} max={1} step='any' value={volume} onChange={handleVolumeChange} />
        </div>
        <div className='duration'>
            <Duration seconds={duration * played} />/<Duration seconds={duration * loaded} />
        </div>
        <input className='seekControl'
          type='range' min={0} max={0.999999} step='any'
          value={played}
          onMouseDown={handleSeekMouseDown}
          onChange={handleSeekChange}
          onMouseUp={handleSeekMouseUp}
          onTouchStart={handleSeekMouseDown}
          onTouchMove={handleSeekChange}
          onTouchEnd={handleSeekMouseUp}
        />
      </div>
    </div>
  );
}

AudioPlayer.propTypes = {
  track: PropTypes.object.isRequired,
  skin: PropTypes.string.isRequired,
  autoNext: PropTypes.bool.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

