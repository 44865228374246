import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { hints, RIGHT_IMG, WRONG_IMG } from '../common/hints';

function checkAllAnswered(answers) {
  for(let a of answers) {
    if (a !== 1) {
      return false;
    }
  }
  return true;
}

function checkAllAnsweredOptions(answers, totalCount) {
  let current = 0;
  for(let a of answers) {
    if (a === 1) {
      current++;
    }
  }
  return current === totalCount;
}

function getWrongIndex(answers) {
  for(let i = 0; i < answers.length; i++) {
    if (answers[i] === -1) {
      return i;
    }
  }
  return -1;
}

export default function ChooseSlide({ card, contentPath, isRTL, isLandscape, onComplete}) {
  const ITEM_PER_ROW = 3;

  const [answered, setAnswered] = useState(new Array(card.optionsList.length).fill(0));

  const [questions, setQuestions] = useState([]);
  const [question, setQuestion] = useState(null);
  const [totalCorrectAnswers, setTotalCorrectAnswers] = useState(0);

  useEffect(() => {
    const q = card;
    let totalCorrectAnswers = 0;
    for(let o of q.optionsList) {
      if (o.answer) {
        totalCorrectAnswers++;
      }
    }
    setTotalCorrectAnswers(totalCorrectAnswers);
    q.optionsList = hints.shuffleArray(q.optionsList);
    q.optionsList = hints.paging(ITEM_PER_ROW, q.optionsList);
    setQuestion(q);
    console.log(q);
  }, [card]);

  useEffect(() => {
    // if there is a wrong answer should clear it after 1 sec.
    let i = getWrongIndex(answered);
    if (i >= 0) {
      const timer = setTimeout(() => {
        if (answered[i] === -1) {
          let a = [...answered];
          a[i] = 0;
          setAnswered(a);
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [answered]);

  const handleOptionClick = (index, option) => {
    if (answered[index] !== 1) {
      let result = option.answer ? 1 : -1;
      let a = [...answered];
      a[index] = result;
      setAnswered(a);
      if (result === 1) {
        hints.playRight();
        if (card.format === 'question-options' && checkAllAnswered(a)) {
          onComplete();
        } else if (card.format === 'options' && checkAllAnsweredOptions(a, totalCorrectAnswers)) {
          onComplete();
        }
      } else {
        hints.playWrong();
      }
    }
  };

  const getImg = (img) => {
    return `${contentPath}/img/${img}`;
  }

  const isImg = (txt) => {
    return txt != null && txt.toLowerCase().indexOf('.png') > 0;
  }

  let questionWidth =  99 / questions.length + '%';
  let layoutImgClass = 'layout';
  let connectedClass = '';
  let questionHeight = '';
  if (card.optionsFormat === 'connected') {
    questionWidth = '40%';
    questionHeight = '50%';
    layoutImgClass += ' layout-wrap';
    connectedClass = '-connected';
  } else if (card.optionsFormat === 'text') {
    connectedClass = '-txt';
  } else {
    connectedClass = '-img';
  }
  let optionRowHeight = '';
  let optionItemWidth = '';
  if (question !== null) {
    optionRowHeight = 90 / question.optionsList.length + '%';
    optionItemWidth = 90 / 3 + '%';
  }
  let questionClass = 'question';
  questionClass += ' qborder';

  // console.log(question, connectedClass);
  // if (question != null && connectedClass === '-connected') {
  // } else {
  //   optionItemWidth = '';
  // }
  // console.log(optionItemWidth);
  let id = 0;
  return (
    <>
      <div className='title'>
        {question && <div className='title-txt'>{question.exText}</div>}
      </div>
      {/* {questions.length > 0 &&
        <div className={layoutImgClass} style={{ height: rowHeight }}>
          {questions.map((question, questionIndex) => (
            <div key={questionIndex} className={questionClass} style={{ width: questionWidth, height: questionHeight }}>
              <div className='question-container'>
                {hints.isImg(question.qImg, question.qTxt) && <img className='img' src={hints.getImg(question.qImg, id, question.qTxt)}></img>}
                {hints.isTxt(question.qTxt) && <span className='question-text'>{question.qTxt}</span>}
              </div>
              {answered[questionIndex] === 1 && <img className='answer-sign' src={RIGHT_IMG}/>}
              {answered[questionIndex] === -1 && <img className='answer-sign' src={WRONG_IMG}/>}
              <ul className={'options-list' + connectedClass}>
                {question.options.map((option, index) => (
                  <li key={index} className={'option-item' + connectedClass}
                    onClick={() => {handleOptionClick(questionIndex, option)}}>
                    {card.optionsFormat === 'image' ?
                      <img src={hints.getImg(option.optImg, id, option.optTxt)}/> :
                      <div className="option-item-text">{option.optTxt}</div>
                    }
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      } */}
      {question != null &&
        <div className='opt-layout' >
          <div className='opt-options-rows'>
            {question.optionsList.map((row, rowIndex) => (
              <div  key={rowIndex}
                    className={'opt-options-row' + connectedClass}
                    style={{ height: optionRowHeight }}>
                {row.map((option, index) => (
                  <div  key={index}
                        className={'opt-option-item' + connectedClass}
                        style={{ maxWidth: optionItemWidth }}
                        onClick={() => {handleOptionClick(rowIndex * ITEM_PER_ROW + index, option)}}>
                    {answered[rowIndex * ITEM_PER_ROW + index] === 1 && <img className='answer-sign' src={RIGHT_IMG}/>}
                    {answered[rowIndex * ITEM_PER_ROW + index] === -1 && <img className='answer-sign' src={WRONG_IMG}/>}

                    {isImg(option.opt) ?
                      <img src={getImg(option.opt)}/> :
                      <div className="option-item-txt-only">{option.opt}</div>
                    }
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      }
    </>
  );
}

ChooseSlide.propTypes = {
  card: PropTypes.object.isRequired,
  contentPath: PropTypes.string.isRequired,
  isRTL: PropTypes.bool.isRequired,
  isLandscape: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired
};