import React from 'react';
import PropTypes from 'prop-types';
import PackageItem from './PackageItem';

export default function PackageMulti({ packageName, params }) {
  return (
    <div>
      <h1 className='packageTitle'>{params.package.title}</h1>
      {params.book.map((book, index) => (
        <PackageItem key={index} index={index} packageName={packageName} params={book} />
      ))}
    </div>
  );
}

PackageMulti.propTypes = {
  packageName: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired
};
