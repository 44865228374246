import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AudioPlayer from '../audioPlayer/AudioPlayer';
import TrackItem from './TrackItem';
import './AudioStories.css';

export default function AudioStories({ packageName, bookName }) {

  const [options, setOptions] = useState({
    headerTitle: ""
  });
  const [playList, setPlayList] = useState([]);
  const [currentTrack, setCurrentTrack] = useState(0);

  useEffect(() => {
    fetch(`/content/${packageName}/books/${bookName}/data.json`)
      .then(resp => resp.json())
      .then(result => {
        setOptions(result.options);
        let trackList = [];
        for (const audioInfo of result.audioInfo) {
          let track = {
            title: audioInfo.title,
            icon: `/content/${packageName}/books/${bookName}/icon/${audioInfo.icon}`,
            url: `/content/${packageName}/books/${bookName}/snd/${audioInfo.name}`,
            subTitle: audioInfo.unitTitle,
            page: audioInfo.page
          };
          trackList.push(track);
        };
        setPlayList(trackList);
      });
  }, [packageName, bookName]);

  const handlePrevious = () => {
    if (currentTrack > 0) {
      setCurrentTrack(currentTrack - 1)
    }
  }

  const handleNext = () => {
    if (currentTrack < playList.length - 1) {
      setCurrentTrack(currentTrack + 1)
      return true
    }
    return false
  }

  const handleClickTrack = (index) => {
    setCurrentTrack(index)
  }

  let skin = `/content/${packageName}/books/${bookName}/skin/`;
  // if there are lots of items then this is probably a page per track and we should
  // move to the next item automatically
  let autoNext = playList.length > 12;
  return (
    <div className='audioStories' style={{ backgroundColor: `${options.selStartColor}` }}>
      {playList.length > 0 && <>
        <div className='audioPlayer'>
          <AudioPlayer track={playList[currentTrack]} skin={skin} autoNext={autoNext} onPrevious={handlePrevious} onNext={handleNext} />
        </div>
        <div className='playList'>
          {playList.map((track, index) => (
            <TrackItem
              key={index}
              track={track}
              isSelected={index === currentTrack}
              options={options}
              onClick={() => handleClickTrack(index)} />
          ))}
        </div>
      </>}
    </div>
  );
}

AudioStories.propTypes = {
  packageName: PropTypes.string.isRequired,
  bookName: PropTypes.string.isRequired
};

