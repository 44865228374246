export default function TrackItem({ track, isSelected, options, onClick }) {
  const styles = {
    track: {
      "--backgroundColor": options.startColor,
      "--textColor": options.fabColor
    },
    trackSelected: {
      backgroundColor: options.fabColor,
      color: options.startColor,
    }
  }

  let style = isSelected ? styles.trackSelected : styles.track
  return (
    <div className="track" style={style} onClick={onClick}>
      <img className='trackIcon' src={track.icon} />
      <div className="trackInfo">
        <div className="trackTitle">{track.title}</div>
        <div className="trackSub">
          {track.subTitle && <div className="trackSubTitle">{track.subTitle}</div>}
          {track.page && <div className="trackPage">Page: {track.page}</div>}
        </div>
      </div>
    </div>
  )
}
