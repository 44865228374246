import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import { hints } from '../common/hints';

function checkAllAnswered(allSet, answers) {
  let valid = 0;
  for(let a of allSet) {
    if (a.match.length > 0) {
      valid++;
    }
  }
  let c = 0;
  for(let a of answers) {
    if (a === 1) { c++; }
  }
  return c === valid;
}

function getWrongIndex(answers) {
  for(let i = 0; i < answers.length; i++) {
    if (answers[i] === -1) {
      return i;
    }
  }
  return -1;
}

export default function MatchSlide({ card, contentPath, isRTL, isLandscape, onComplete }) {
  const [setA, setSetA] = useState([]);
  const [setB, setSetB] = useState([]);
  const [answered, setAnswered] = useState(new Array(card.setB.length).fill(0));
  const draggingItem = useRef();
  const dragOverItem = useRef();

  useEffect(() => {
    const resizeListener = () => {
      let el = $('.drag-item-container');
      let w = el.width();
      let h = el.height();
      if (w > 0) { $('.drag-item-sub').width(w); }
      if (h > 0) { $('.drag-item-sub').height(h); }
    };
    // set resize listener
    window.addEventListener('load', resizeListener);
    window.addEventListener('resize', resizeListener);
    resizeListener();
    return () => {
      window.removeEventListener('load', resizeListener);
      window.removeEventListener('resize', resizeListener);
    };
  });

  useEffect(() => {
    const setA = hints.shuffleArray(card.setA);
    const setB = hints.shuffleArray(card.setB);
    setSetA(setA);
    setSetB(setB);
  }, [card]);

  useEffect(() => {
    // if there is a wrong answer should clear it after 1 sec.
    let i = getWrongIndex(answered);
    if (i >= 0) {
      const timer = setTimeout(() => {
        if (answered[i] === -1)  {
          let a = [...answered];
          a[i] = 0;
          setAnswered(a);
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [answered]);

  const doDrop = (srcIndex, trgIndex) => {
    if (answered[trgIndex] !== 1) {
      let a = [...answered];
      let result = setA[srcIndex].option === setB[trgIndex].match ? 1 : -1;
      a[trgIndex] = result;
      setAnswered(a);
      if (result > 0) {
        hints.playRight();
        if (checkAllAnswered(card.setB, a)) {
          onComplete();
        }
        return true;
      } else {
        hints.playWrong();
      }
    }
    return false;
  }

  const handleDrop = (e) => {
    // this is being called twice and should not handle the change of state
    draggingItem.current = e.dragData.index;
    dragOverItem.current = e.dropData.index;
  }

  const handleDragEnter = (e, trgIndex) => {
    if (card.removeAfterDrop || (!card.removeAfterDrop && answered[trgIndex] !== 1)) {
      e.target.style.border = '3px dashed red';
    }
  }

  const handleDragLeave = (e) => {
    e.target.style.border = '';
  }

  const handleHit = (e) => {
    // because sometimes handleDrop get called twice we should change state here
    e.target.style.border = '';
    if (doDrop(draggingItem.current, dragOverItem.current) && card.removeAfterDrop) {
      e.containerElem.style.visibility = 'hidden';
    }
  }

  const getImg = (img) => {
    if (img.toLowerCase().indexOf('.png') > 0) {
      let src = `${contentPath}/img/${img}`;
      return <img className='item-img' src={src}/>
    } else {
     return <>{img}</>
    }
  }

  if (isLandscape) {
    card.format = 'rows';
  } else {
    card.format = 'columns';
  }
  let displayMode = 'column';
  let itemClass = 'item-column';

  if (card.format === 'rows') {
    displayMode = 'row';
    itemClass = 'item-row';
    if (!isRTL) {
      itemClass += " item-ltr"
    }
  }

  let itemHeightA = '80%';
  let itemWidthA = '80%';

  let itemHeightB = '80%';
  let itemWidthB = '80%';
  if (displayMode === 'column') {
    itemHeightA = card.setA.length === 1 ? '50%' : 80 / card.setA.length + '%';
    itemHeightB = 80 / card.setB.length + '%';
  } else {
    itemWidthA = card.setA.length === 1 ? '50%' : 80 / card.setA.length + '%';
    itemWidthB = card.setB.length === 1 ? '50%' : 80 / card.setB.length + '%';
  }

  // device detection
  // if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
  //     || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
  //     if (isLandscape) {
  //       $('.item-row').css({ "padding":"0px" });
  //     }
  // }

  return (
    <>
      <div className='title'>
        {card.exText &&
          <div className='title-txt'>
            {card.exText}
          </div>
        }
      </div>
      <div className={card.format}>
        <div className={displayMode}>
          {setA.map((opt, index) => (
            <div
              key={index}
              className={itemClass + ' drag-item-container'}
              style={{ width: itemWidthA, height: itemHeightA }}>
              <DragDropContainer
                targetKey='foo'
                dragData={{index}}
                onDrop={handleDrop}
                dragElemOpacity={1}>
                <div className='drag-item-sub'>
                  {getImg(opt.option)}
                </div>
              </DragDropContainer>
            </div>
          ))}
        </div>
        <div className={displayMode}>
          {setB.map((opt, index) => (
            <div
              key={index}
              className={itemClass}
              style={{ width: itemWidthB, height: itemHeightB }}>
              {answered[index] !== 0 &&
                <div className='answer-sign-container'>
                  {answered[index] === 1 && <img className='answer-sign' src='/interface/right.png'/>}
                  {answered[index] === -1 && <img className='answer-sign' src='/interface/wrong.png'/>}
                </div>
              }
              <DropTarget
                targetKey='foo'
                dropData={{index}}
                onDragEnter={(e) => handleDragEnter(e, index)}
                onDragLeave={handleDragLeave}
                onHit={handleHit}>
                {getImg(opt.option)}
              </DropTarget>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

MatchSlide.propTypes = {
  card: PropTypes.object.isRequired,
  contentPath: PropTypes.string.isRequired,
  isRTL: PropTypes.bool.isRequired,
  isLandscape: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired
};