import PropTypes from 'prop-types';
import AudioStories from '../audioStories/AudioStories';
import VideoStories from '../videoStories/VideoStories';
import Exercises from '../exercises/Exercises';

export default function Book({ packageName, params }) {

  if (params.data_type === 1) {
    return (
      <AudioStories
        packageName={packageName}
        bookName={params.bookName} />
    )
  } else if (params.data_type === 5) {
    return (
      <VideoStories
        packageName={packageName}
        bookName={params.bookName} />
    )
  } else if (params.data_type === 7) {
    return (
      <Exercises
        packageName={packageName}
        bookName={params.bookName} />
    )
  }
  return (
    <div>{params.bookName}</div>
  );
}

Book.propTypes = {
  packageName: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired
};