import React from 'react';
import Package from './package/Package'
import { BrowserRouter, Route, Routes } from "react-router-dom";

function DefaultPage() {
  return (
    <>
      <h1>Welcome to Learning Express</h1>
      {(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') &&
      <ul>
        <li><a href="/EJKG1">English Journey KG1</a></li>
        <li><a href="/EJKG1_S">English journey KG1 stories</a></li>
        <li><a href="/EJKL1_S">English journey Level 1 stories</a></li>
        <li><a href="/EJL3">English Journey L3</a></li>
        <li><a href="/EJL4">English Journey L4</a></li>
        <li><a href="/Omstories1">ALL set to read 1</a></li>
        <li><a href="/Omstories2">ALL set to read 2</a></li>
        <br/>
        <li><a href="/SSPS">Smart Scholar Pre School</a></li>
        <li><a href="/SSKG">Smart Scholar Kindergarten</a></li>
        <li><a href="/SSG1">Smart Scholar Grade 1</a></li>
        <li><a href="/SSG2">Smart Scholar Grade 2</a></li>
        <li><a href="/SSG3">Smart Scholar Grade 3</a></li>
      </ul>}
    </>
  );
};

function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route
          path="/"
          element={<DefaultPage />}
        />
        <Route
          path="/:packageName"
          element={<Package />}
        />
        <Route
          path="/:packageName/:bookIndex"
          element={<Package />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
