import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import PackageMulti from './PackageMulti';
import Book from './Book';
import './Package.css';

export default function Package() {

  const [params, setParams] = useState({
    package: {
      folderName: "",
      title: "",
      titles_rtl: false
    },
    book: []
  });

  const { packageName, bookIndex } = useParams();

  useEffect(() => {
    fetch(`/content/${packageName}/data.json`)
      .then(resp => resp.json())
      .then(result => {
        setParams(result);
        document.title = result.package.title;
      });
  }, [packageName]);

  return (
    <>
      {bookIndex && params.book.length > 0 ?
        <Book packageName={packageName} params={params.book[bookIndex]} />
        : params.book.length === 1 ?
          <Book packageName={packageName} params={params.book[0]} />
        : params.book.length > 0 &&
          <PackageMulti packageName={packageName} params={params} />
      }
    </>
  );
}