import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import MatchSlide from './MatchSlide';
import MatchSlideMulti from './MatchSlideMulti';
import { hints } from '../common/hints';
import './Match.css';

export default function Match({ packageName, bookName, exId, isRTL }) {

  const [params, setParams] = useState(null)

  let isLandscape = false;

  useEffect(() => {
    fetch(`/content/${packageName}/${bookName}/${exId}/exercise.json`)
    .then(resp => resp.json())
    .then(result => {
      setParams(result)
    })
  }, [packageName, bookName, exId]);

  useEffect(() => {
    const resizeListener = () => {
      $('.columns').height(window.innerHeight - 110);
      $('.rows').height(window.innerHeight - 110);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);
    resizeListener();
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  });

  const handleComplete = () => {
    hints.playAllRight(isRTL);
  }

  let contentPath = `/content/${packageName}/${bookName}/${exId}`;
  return (
    <div className='match-exercise'>
      {params && (params.removeAfterDrop ?
        <MatchSlideMulti
          card={params}
          contentPath={contentPath}
          isRTL={isRTL}
          isLandscape={isLandscape}
          onComplete={handleComplete} />
        :
        <MatchSlide
          card={params}
          contentPath={contentPath}
          isRTL={isRTL}
          isLandscape={isLandscape}
          onComplete={handleComplete} />
      )}
    </div>
  );
}

Match.propTypes = {
  packageName: PropTypes.string.isRequired,
  bookName: PropTypes.string.isRequired,
  exId: PropTypes.string.isRequired,
  isRTL: PropTypes.bool.isRequired,
};