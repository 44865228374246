import PropTypes from 'prop-types';

export default function PackageItem({ index, packageName, params }) {

  let icon = ''
  switch (params.data_type) {
    case 1:
      icon = '/interface/audioicon.png';
      break;
    case 5:
      icon = '/interface/videoicon.png';
      break;
    case 6:
      icon = '/interface/pdficon.png';
      break;
    default:
      icon = '';
      break;
  }

  let url = `/${packageName}/${index}`;
  if (params.data_type === 6) {
    url = params.url;
    if (url.charAt(url.length-1) !== "+") {
      url += "+";
    }
  } else if (params.data_type === 7) {
    icon = `/content/${packageName}/interface/${params.bookName}.png`;
  }
  return (
    <a className='packageItem' href={url}>
      <img className='packageItemIcon' src={icon} />
      <h3>{params.title}</h3>
    </a>
  );
}

PackageItem.propTypes = {
  index: PropTypes.number.isRequired,
  packageName: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired
};