import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import ChooseSlide from './ChooseSlide';
import { hints } from '../common/hints';
import './Choose.css';

export default function Choose({ packageName, bookName, exId, isRTL }) {

  const [params, setParams] = useState(null)

  let isLandscape = false;

  useEffect(() => {
    fetch(`/content/${packageName}/${bookName}/${exId}/exercise.json`)
    .then(resp => resp.json())
    .then(result => {
      setParams(result)
    })
  }, [packageName, bookName, exId]);

  useEffect(() => {
    const resizeListener = () => {
      $('.choose-exercise').height(window.innerHeight - 110);
      $('.choose-exercise').width(window.innerWidth - 10);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);
    resizeListener();
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  });

  const handleComplete = () => {
    hints.playAllRight(isRTL);
  }

  let contentPath = `/content/${packageName}/${bookName}/${exId}`;
  return (
    <div className='choose-exercise'>
    {params &&
      <ChooseSlide
        card={params}
        contentPath={contentPath}
        pageId={exId}
        isRTL={isRTL}
        isLandscape={isLandscape}
        onComplete={handleComplete} />
    }
  </div>
  );
}

Choose.propTypes = {
  packageName: PropTypes.string.isRequired,
  bookName: PropTypes.string.isRequired,
  exId: PropTypes.string.isRequired,
  isRTL: PropTypes.bool.isRequired,
};