import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import VideoPlayer from '../videoPlayer/VideoPlayer';
import VideoItem from './VideoItem';
import './VideoStories.css';

export default function VideoStories({ packageName, bookName }) {

  const [options, setOptions] = useState({
    headerTitle: ""
  });
  const [playList, setPlayList] = useState([]);
  const [currentTrack, setCurrentTrack] = useState(0);

  useEffect(() => {
    fetch(`/content/${packageName}/books/${bookName}/data.json`)
      .then(resp => resp.json())
      .then(result => {
        setOptions(result.options);
        let videoList = [];
        for (const videoInfo of result.videoInfo) {
          let track = {
            title: videoInfo.title,
            icon: `/content/${packageName}/books/${bookName}/icon/${videoInfo.icon}`,
            url: `/content/${packageName}/books/${bookName}/video/${videoInfo.name}`,
            subTitle: videoInfo.unitTitle,
            page: videoInfo.page
          };
          videoList.push(track);
        };
        setPlayList(videoList);
      });
  }, [packageName, bookName]);

  const handleNext = () => {
    if (currentTrack < playList.length - 1) {
      setCurrentTrack(currentTrack + 1)
      return true
    }
    return false
  }

  const handleClickTrack = (index) => {
    setCurrentTrack(index)
  }

  return (
    <div className='videoStories' style={{ backgroundColor: `${options.selStartColor}` }}>
      {playList.length > 0 && <>
        <div className='videoPlayer' style={{ height: '400px' }}>
          <VideoPlayer track={playList[currentTrack]} autoNext={true} onNext={handleNext} />
        </div>
        <div className='videoPlayList'>
          {playList.map((track, index) => (
            <VideoItem
              key={index}
              track={track}
              isSelected={index === currentTrack}
              options={options}
              onClick={() => handleClickTrack(index)} />
          ))}
        </div>
      </>}
    </div>
  );
}

VideoStories.propTypes = {
  packageName: PropTypes.string.isRequired,
  bookName: PropTypes.string.isRequired
};

