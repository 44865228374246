import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import maphilight from 'maphilight';
import { hints } from '../common/hints';

function checkAllAnswered(answers) {
  let c = 0;
  for(let a of answers) {
    if (a === 1) { c++; }
  }
  return c === answers.length;
}

function hilightOn(element) {
  var data = $(element).trigger('mouseout').data('maphilight') || {};
  data.alwaysOn = true;
  $(element).data('maphilight', data).trigger('alwaysOn.maphilight');
}

function hilightColor(element, fillColor) {
  var data = $(element).data('maphilight') || {};
  data.fillColor = fillColor;
  $(element).data('maphilight', data);
}

function hilightOff(element) {
  var data = $(element).trigger('mouseout').data('maphilight') || {};
  data.alwaysOn = false;
  $(element).data('maphilight', data).trigger('alwaysOn.maphilight');
}

export default function MapClickSlideSlide({ card, contentPath, pageId, isRTL, isLandscape, onComplete }) {

  const [answered, setAnswered] = useState(new Array(card.qstList[0].answer.length).fill(0));
  const [wrongAnswers, setWrongAnswers] = useState([]);
  const [lastAnswer, setLastAnswer] = useState(-1);

  const resizeListener = () => {
    let mainImg = $(`#mainImg${pageId}`);
    let imgContainer = $(`#imgContainer${pageId}`);

    let ow = mainImg.width();
    let oh = mainImg.height();
    if (ow > 0 && ow > 0) {
      let nw = imgContainer.width();
      let scalew = nw / ow;
      // console.log(ow, nw, scalew);

      let nh = window.innerHeight - 200;
      let scaleh = nh / oh;
      // console.log(oh, nh, scaleh);

      let scale = Math.min(scalew, scaleh);
      // console.log(scale);
      if (scale < 1) {
        imgContainer.css({transform: `scale(${scale})`});
      }
    }
  };

  useEffect(() => {
    // set resize listener
    // window.addEventListener('load', resizeListener);
    window.addEventListener('resize', resizeListener);
    // resizeListener();
    $(`#mainImg${pageId}`).maphilight();
    return () => {
      // window.removeEventListener('load', resizeListener);
      window.removeEventListener('resize', resizeListener);
    };
  });

  const handleImgLoad = () => {
    resizeListener();
  }

  useEffect(() => {
    // if there is a wrong answer should clear it after 1 sec.
    if (wrongAnswers.length > 0) {
      const timer = setTimeout(() => {
        wrongAnswers.forEach(w => {
          let ele = `#area${pageId}-${w}`;
          hilightOff(ele);
        });
        setWrongAnswers([]);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [wrongAnswers]);



  const handleClick = (e, pageId, id, index) => {
    e.preventDefault();
    // console.log('handleClick', pageId, id);
    let ele = `#area${pageId}-${id}`;
    let i = card.qstList[0].answer.indexOf(id);
    // console.log(pageId, id, index);
    let isCorrect = false;
    if (card.answerInOrder) {
      if (i >= 0 && i == lastAnswer + 1) {
        isCorrect = true;
      }
    } else if (i >= 0) {
      isCorrect = true;
    }
    if (isCorrect) {
      //answerInOrder
      // it is a correct answer
      let a = [...answered];
      a[i] = 1;
      setAnswered(a);
      setLastAnswer(i);
      hilightColor(ele, '00ff00');
      hilightOn(ele);
      if (checkAllAnswered(a)) {
        onComplete();
      } else {
        hints.playRight();
      }
    } else {
      let w = [...wrongAnswers];
      w.push(id);
      setWrongAnswers(w);
      hilightColor(ele, 'ff0000');
      hilightOn(ele);
      hints.playWrong();
    }
  }

  let imgPath = `${contentPath}/img/${card.img}`;
  return (
    <>
      <div className='title'>
        {card.exText &&
          <div className='title-txt'>
            {card.exText}
          </div>
        }
      </div>
      <div id="mapContainer">
      <div id={`imgContainer${pageId}`} className='imgContainer'>
        <img  id={`mainImg${pageId}`}
              className='img' src={imgPath}
              useMap={`#imgMap${pageId}`}
              onLoad={handleImgLoad}
              />
        <map name={`imgMap${pageId}`}>
          {card.mapAreas.map((area, index) => (
            <area key={index}
                  id={`area${pageId}-${area.id}`}
                  shape={area.shape}
                  coords={area.coords}
                  onClick={(e) => handleClick(e, pageId, area.id, index)}
                  href="#" />
          ))}
        </map>
      </div>
      </div>
    </>
  );
}

MapClickSlideSlide.propTypes = {
  card: PropTypes.object.isRequired,
  contentPath: PropTypes.string.isRequired,
  pageId: PropTypes.number.isRequired,
  isRTL: PropTypes.bool.isRequired,
  isLandscape: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired
};