export default function VideoItem({ track, isSelected, options, onClick }) {
  const styles = {
    track: {
      "--backgroundColor": options.startColor,
      "--textColor": options.seperaterColor
    },
    trackSelected: {
      backgroundColor: options.seperaterColor,
      color: options.startColor,
    }
  }

  let style = isSelected ? styles.trackSelected : styles.track
  return (
    <div className="videoTrack" style={style} onClick={onClick}>
      <img className='videoTrackIcon' src={track.icon} />
      <div className="videoTrackInfo">
        <div className="videoTrackTitle">{track.title}</div>
        <div className="videoTrackSub">
          {track.subTitle && <div className="videoTrackSubTitle">{track.subTitle}</div>}
          {track.page && <div className="videoTrackPage">Page: {track.page}</div>}
        </div>
      </div>
    </div>
  )
}
