import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import { hints } from '../common/hints';

function checkAllAnswered(answers, count) {
  let c = 0;
  for(let a of answers) {
    if (a === 1) { c++; }
  }
  return c === count;
}

function getWrongIndex(answers) {
  for(let i = 0; i < answers.length; i++) {
    if (answers[i] === -1) {
      return i;
    }
  }
  return -1;
}

export default function CompleteSlide({ card, contentPath, pageId, isRTL, isLandscape, onComplete }) {
  const [answered, setAnswered] = useState(new Array(card.questions.length).fill(0));
  const [connectedComplete, setConnectedComplete] = useState(false);
  const [completeQuestions, setCompleteQuestions] = useState([]);
  const [completeChoices, setCompleteChoices] = useState([]);
  const draggingItem = useRef();
  const dragOverItem = useRef();

  useEffect(() => {
    if (card.isConnected) {
      setCompleteQuestions(card.questions);
      setCompleteChoices(hints.shuffleArray(card.choices));
    } else {
      setCompleteQuestions(hints.shuffleArray(card.questions));
      setCompleteChoices(hints.shuffleArray(card.choices));
    }
  }, [card]);

  useEffect(() => {
    // if there is a wrong answer should clear it after 1 sec.
    let i = getWrongIndex(answered);
    if (i >= 0) {
      const timer = setTimeout(() => {
        if (answered[i] === -1) {
          let a = [...answered];
          a[i] = 0;
          setAnswered(a);
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [answered]);

  const handleDrop = (e) => {
    // this is being called twice and should not handle the change of state
    draggingItem.current = e.dragData.index;
    dragOverItem.current = e.dropData.index;
  }

  const handleDragEnter = (e, trgIndex) => {
    if (answered[trgIndex] !== 1) {
      if (e.target.className === 'qstText') {
        e.target.parentElement.style.border = '3px dashed red';
      } else {
        e.target.style.border = '3px dashed red';
      }
    }
  }

  const handleDragLeave = (e) => {
    e.target.style.border = '';
    e.target.parentElement.style.border = '';
  }

  const handleHit = (e) => {
    // because sometimes handleDrop get called twice we should change state here
    e.target.style.border = '';
    e.target.parentElement.style.border = '';
    doDrop(draggingItem.current, dragOverItem.current);
  }

  const doDrop = (srcIndex, trgIndex) => {
    if (trgIndex != null) {
      let value = $('#qst' + pageId + '_' + trgIndex).text();
      let choice = completeChoices[srcIndex];
      value = value.replace('...', choice);
      if (value.indexOf('...') < 0) {
        let result = value === completeQuestions[trgIndex].answer ? 1 : -1;
        let a = [...answered];
        a[trgIndex] = result;
        setAnswered(a);
        if (result === 1) {
          $('#qst' + pageId + '_' + trgIndex).text(value);
          hints.playRight();
          if (checkAllAnswered(a, completeQuestions.length)) {
            onComplete();
            setConnectedComplete(true);
          }
        } else {
          $('#qst' + pageId + '_' + trgIndex).text(completeQuestions[trgIndex].value);
          hints.playWrong();
        }
      } else {
        $('#qst' + pageId + '_' + trgIndex).text(value);
      }
    }
    draggingItem.current = null;
    dragOverItem.current = null;
  }

  const getImg = (img) => {
    return `${contentPath}/img/${img}`;
  }

  // keep 10% margin
  let maxWidth = (90 / card.questions.length) + '%';
  return (
    <>
      <div className='title'>
        {card.exText &&
          <div className='title-txt'>
            <div>{card.exText}</div>
          </div>
        }
      </div>
      {completeQuestions.length > 0 &&
        <div className='ex-grid'>
          <div className='choice-row'>
            {completeChoices.map((letter, index) => (
              <DragDropContainer key={index}
                targetKey='foo'
                dragData={{index}}
                onDrop={handleDrop}
                dragElemOpacity={0.5}>
                <div className='ex-letters'>
                  {letter}
                </div>
              </DragDropContainer>
            ))}
          </div>
          {!card.isConnected &&
            <div className='ex-row'>
              {completeQuestions.map((question, qstIndex) => (
                <div key={qstIndex} className='ex-box'>
                  {answered[qstIndex] === 1 && <img className='answer-sign' src='/interface/right.png'/>}
                  {answered[qstIndex] === -1 && <img className='answer-sign' src='/interface/wrong.png'/>}
                  <DropTarget
                    targetKey='foo'
                    dropData={{index: qstIndex}}
                    onDragEnter={(e) => handleDragEnter(e, qstIndex)}
                    onDragLeave={handleDragLeave}
                    onHit={handleHit}>
                    {question.image != null &&
                      <div className="qstImg">
                        <img src={getImg(question.image)}/>
                      </div>
                    }
                    <div className="qstText">
                      <div id={'qst' + pageId + '_' + qstIndex}>{question.value}</div>
                    </div>
                  </DropTarget>
                </div>
              ))}
            </div>
          }
          {card.isConnected &&
            <div className='ex-row qstConnected'>
              {connectedComplete && <img className='answer-sign' src='/interface/right.png'/>}
              {completeQuestions.map((question, qstIndex) => (
                <DropTarget key={qstIndex}
                    targetKey='foo'
                    dropData={{index: qstIndex}}
                    onDragEnter={(e) => handleDragEnter(e, qstIndex)}
                    onDragLeave={handleDragLeave}
                    onHit={handleHit}>
                  <span id={'qst' + pageId + '_' + qstIndex}  className='qstConnectedText'>
                    {question.value}
                  </span>
                </DropTarget>
              ))}
            </div>
          }
        </div>
      }
    </>
  );
}

CompleteSlide.propTypes = {
  card: PropTypes.object.isRequired,
  contentPath: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
  isRTL: PropTypes.bool.isRequired,
  isLandscape: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired
};