import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import MapClickSlide from './MapClickSlide';
import { hints } from '../common/hints';
import './MapClick.css';

export default function MapClick({ packageName, bookName, pageId, exId, isRTL }) {

  const [params, setParams] = useState(null)

  let isLandscape = false;

  useEffect(() => {
    fetch(`/content/${packageName}/${bookName}/${exId}/exercise.json`)
    .then(resp => resp.json())
    .then(result => {
      setParams(result)
    })
  }, [packageName, bookName, exId]);

  useEffect(() => {
    const resizeListener = () => {
      $('.map-click-exercise').height(window.innerHeight - 110);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);
    resizeListener();
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  });

  const handleComplete = () => {
    hints.playAllRight(isRTL);
  }

  let contentPath = `/content/${packageName}/${bookName}/${exId}`;
  return (
    <div className='map-click-exercise'>
      {params &&
        <MapClickSlide
          card={params}
          contentPath={contentPath}
          pageId={pageId}
          isRTL={isRTL}
          isLandscape={isLandscape}
          onComplete={handleComplete} />
      }
    </div>
  );
}

MapClick.propTypes = {
  packageName: PropTypes.string.isRequired,
  bookName: PropTypes.string.isRequired,
  pageId: PropTypes.number.isRequired,
  exId: PropTypes.string.isRequired,
  isRTL: PropTypes.bool.isRequired,
};